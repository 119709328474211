import React from "react";
import {
  Toast,
  Pressable,
  Button,
  XIcon,
  PlayIcon,
  CircularProgress,
  Tag,
  BUTTON_VARIANTS,
  BUTTON_SIZES,
  VisuallyHidden,
} from "@gonoodle/gn-universe-ui";
import { Dialog } from "@gonoodle/gn-universe-ui/lib/next";
import { differenceInDays } from "date-fns";
import { twMerge } from "tailwind-merge";

import TrophyAvatar from "../TrophyAvatar";
import BadgeDetails from "./BadgeDetails";
import {
  useBadgeQuery,
  useClaimBadgeMutation,
  useBadgeClaimCount,
  useBadgeRulesCombinedProgress,
} from "../../hooks";
import { useLogEvent } from "../../contexts/Analytics";
import { useUser } from "../../contexts/user";
import { BADGE_EARNING_MODELS, BADGES_TYPES } from "../../constants";

const VARIANTS = {
  FULL: "full",
  COMPACT: "compact",
};

function TrophyIcon({ ...props }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={18}
      height={18}
      fill="none"
      {...props}
    >
      <path
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M12.375 14.063h-6.75m6.75 0a2.25 2.25 0 0 1 2.25 2.25H3.375a2.25 2.25 0 0 1 2.25-2.25m6.75 0V11.53a.844.844 0 0 0-.844-.844h-.653m-5.253 3.376V11.53c0-.466.378-.844.844-.844h.654m3.755 0H7.123m3.755 0a5.59 5.59 0 0 1-.736-2.378m-3.02 2.379a5.59 5.59 0 0 0 .736-2.38m2.284 0a5.042 5.042 0 0 0 2.06-1.012m-2.06 1.013a5.079 5.079 0 0 1-2.284 0m0 0a5.045 5.045 0 0 1-2.06-1.013m-1.86-4.119c-.737.107-1.466.238-2.188.39a4.502 4.502 0 0 0 4.047 3.729m-1.86-4.119v.198c0 1.581.725 2.992 1.86 3.921m-1.86-4.119V2.041A36.254 36.254 0 0 1 9 1.688c1.718 0 3.409.12 5.063.352v1.137m0 0v.198a5.052 5.052 0 0 1-1.86 3.921m1.86-4.119c.732.107 1.462.237 2.187.39a4.502 4.502 0 0 1-4.047 3.729"
      />
    </svg>
  );
}

const ClaimCount = ({ id, variant, earningModel }) => {
  const { badgeClaimCount } = useBadgeClaimCount({ id });

  return (
    badgeClaimCount > 0 &&
    earningModel === BADGE_EARNING_MODELS.RECURRING && (
      <span
        className={twMerge(
          "flex items-center justify-center bg-white p-2 rounded-full text-black font-extrabold",
          variant === VARIANTS.FULL ? "w-9 h-9 text-md" : "w-4 h-4 text-xs",
        )}
      >
        {badgeClaimCount}
      </span>
    )
  );
};

const BadgeCircularProgress = ({ id, children, ...delegated }) => {
  const { combinedScore, combinedScoreNeeded } = useBadgeRulesCombinedProgress({
    id,
  });

  return (
    <CircularProgress
      value={combinedScore}
      maxValue={combinedScoreNeeded}
      {...delegated}
    >
      {children}
    </CircularProgress>
  );
};

const Avatar = ({
  className,
  defaultImage,
  earnedImage,
  imageProps = {},
  isClaimed,
  title,
}) => (
  <TrophyAvatar
    className={className}
    src={isClaimed ? earnedImage.regular3x : defaultImage.regular3x}
    imageProps={{
      width: 500,
      height: 500,
      fill: false,
      ...imageProps,
    }}
    fallback={title}
  />
);

const Highlight = ({ isNew, isPremium, isLimited }) => {
  const { user } = useUser();
  // The order of these code blocks is a business rule, do not change it.
  if (isLimited) {
    return <Tag variant={Tag.VARIANTS.limited} />;
  }

  if (isPremium) {
    return <Tag variant={Tag.VARIANTS.premium} />;
  }

  if (isNew && user.features.useBadgesNewTag) {
    return <Tag variant={Tag.VARIANTS.new} />;
  }

  return null;
};

export default function Badge({ id, variant = VARIANTS.FULL }) {
  const [isEarningBadge, setIsEarningBadge] = React.useState(false);
  const { badge, isBadgeLoading, badgeError } = useBadgeQuery({ id });
  const alert = Toast.useAlert();

  const {
    defaultImage,
    description,
    earnedImage,
    premium,
    publishStartsAt,
    title: badgeTitle,
    badgeType,
    isClaimed,
    isEarned,
    earningModel,
  } = badge;

  const { logEvent: logBadgeCollectedEvent } = useLogEvent({
    event: "Badge Collected",
    properties: {
      title: badgeTitle,
      badgeType,
    },
  });

  const {
    claimBadge,
    isClaimBadgeLoading,
    resetClaimBadge,
  } = useClaimBadgeMutation({
    onSuccess: () => {
      logBadgeCollectedEvent();
    },
    onError: () => {
      alert.error("Something went wrong. Please try to claim the badge again.");
    },
  });

  const handleBadgeClaim = () => {
    resetClaimBadge();
    claimBadge({ id });

    setIsEarningBadge(true);
  };

  if (isBadgeLoading || badgeError) {
    return null;
  }

  return (
    <Dialog.Root
      size={Dialog.SIZE.FULL}
      onOpenChange={(isOpen) => {
        if (isOpen === false) {
          setIsEarningBadge(false);
        }
      }}
    >
      <Dialog.Trigger asChild={true}>
        <Pressable
          elementType="div"
          className={twMerge(
            "flex flex-col items-center text-white hover:text-mint group",
            variant === VARIANTS.FULL ? "w-full" : "max-w-[70px]",
          )}
        >
          <div className="relative">
            <BadgeCircularProgress
              id={id}
              thickness={variant === VARIANTS.FULL ? 6 : 3}
              size={variant === VARIANTS.FULL ? 270 : 64}
              offset={variant === VARIANTS.FULL ? 3 : 0}
            >
              <Avatar
                title={badgeTitle}
                isClaimed={isClaimed}
                isEarned={isEarned}
                earnedImage={earnedImage}
                defaultImage={defaultImage}
                variant={variant}
                className={twMerge(
                  "aspect-1 w-[64px] overflow-hidden",
                  variant === VARIANTS.FULL && "w-[270px]",
                )}
                imageProps={{
                  className: "group-hover:scale-110 transition-transform",
                }}
              />
            </BadgeCircularProgress>

            <div className="absolute right-0 top-1/4">
              <ClaimCount
                id={id}
                variant={variant}
                earningModel={earningModel}
              />
            </div>

            {isEarned && variant === VARIANTS.FULL ? (
              <div className="absolute inset-0 flex justify-center items-center">
                <Dialog.Trigger asChild={true}>
                  <Button
                    variant={BUTTON_VARIANTS.vivid}
                    size={BUTTON_SIZES.md}
                    disabled={isClaimBadgeLoading}
                    onPress={handleBadgeClaim}
                  >
                    <PlayIcon className="w-7 h-7 mr-2" /> Collect Badge
                  </Button>
                </Dialog.Trigger>
              </div>
            ) : null}

            {isEarned && variant === VARIANTS.COMPACT ? (
              <div className="absolute inset-0 flex justify-center items-center">
                <Dialog.Trigger asChild={true}>
                  <Pressable
                    className="bg-purple p-1 rounded shrink-0"
                    onPress={handleBadgeClaim}
                  >
                    <TrophyIcon width={18} height={18} />
                  </Pressable>
                </Dialog.Trigger>
              </div>
            ) : null}
          </div>

          {variant === VARIANTS.FULL ? (
            <div className="flex flex-col items-start w-full gap-2 mt-2 py-2 px-3">
              <div className="flex flex-row w-full items-start justify-between space-x-2">
                <span className="text-md font-bold">{badgeTitle}</span>

                <Highlight
                  isLimited={badgeType === BADGES_TYPES.LIMITED}
                  isPremium={premium}
                  isNew={
                    differenceInDays(new Date(), new Date(publishStartsAt)) < 7
                  }
                />
              </div>

              <span className="text-sm">{description}</span>
            </div>
          ) : (
            <div className="text-xxs font-semibold text-center mt-[6px] max-w-[70px] text-ellipsis overflow-hidden">
              {badgeTitle}
            </div>
          )}
        </Pressable>
      </Dialog.Trigger>

      <Dialog.Panel
        backdropProps={{
          className: twMerge(
            "bg-[rgba(24,24,27,0.8)]", // gray-900 with 80% opacity
            premium && isEarningBadge && "bg-[rgba(35,14,29,0.8)]",
          ),
        }}
      >
        <div className="container flex flex-col h-full space-y-6 md:space-y-9 lg:py-lg">
          <div className="self-end h-7 w-7 shrink-0">
            <span className="sr-only">Close Badge Dialog</span>

            <Dialog.CloseTrigger>
              <XIcon className="text-white h-full w-full" aria-hidden="true" />
            </Dialog.CloseTrigger>
          </div>

          <VisuallyHidden asChild={true}>
            <Dialog.Title>{badgeTitle}</Dialog.Title>
          </VisuallyHidden>

          <VisuallyHidden asChild={true}>
            <Dialog.Description>{description}</Dialog.Description>
          </VisuallyHidden>

          <BadgeDetails badge={badge} isEarningBadge={isEarningBadge} />
        </div>
      </Dialog.Panel>
    </Dialog.Root>
  );
}

Badge.VARIANTS = VARIANTS;
